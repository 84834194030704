.FON{
    font-family: 'system-ui' !important;
    text-align: center;
    font-size: 130%;
   border-color: #2f648c;
    color: #2f648c;   
}

.FON2{
    font-family: 'system-ui' !important;
    text-align: center;
    font-size: 130%;
    border-color: #9f1d1c;
    color: #9f1d1c;
    
}

/* ------------ */
.prim{
    background-color:#285681;
    border-radius: 14px 0px 0px 0px;
    margin: 0% 0% 0% 2%;
    background: linear-gradient( 60deg, rgb(40 85 128),rgb(40 85 128), rgb(22 117 173));
}

.fontA{
    color: #fff;
    
}
.ziseA{
font-size: '13px';
}
/* ------------ */
.fontB{
    color: #265204;  
}

.fontC{
    color: #0a0080;  
}

.fontAcc{
    color: #808080;  
}

.fontD{
    color: #81157f;  
    font-size: 91%;
}

.fontZ{
}
/* ------------ */
.backprm{
    background-color:#e02b6b;
    margin: 2% 0% 0% 2%;
    background: linear-gradient( 60deg, rgb(174 32 83), rgb(225 46 108));
}
/* ------------ */
/* ------------ */
.backg{
    background-color:#5fc0d3;
    margin: 0% 0% 0% 2%;
    background: linear-gradient(  60deg , rgb(28 149 173),rgb(60 151 169), rgb(95 192 211));
}
/* ------------ */
/* ------------ */
.backprmlast{
    background-color: #79a697;
    border-radius: 0px 0px 0px 14px;
    margin: 2% 0% 0% 2%;
    background: linear-gradient(  60deg, rgb(40 132 101),rgb(61 156 125), rgb(101 160 141));
}

.backprmlastameel{
    background-color: #79a697;
    margin: 2% 0% 0% 2%;
    /* background: linear-gradient(  60deg, rgb(40 132 101),rgb(61 156 125), rgb(101 160 141)); */
    /* background: linear-gradient( 60deg, rgb(174 32 83), rgb(225 46 108)); */
    background: linear-gradient(60deg, rgb(134 9 132), rgb(156, 53, 155));
}

/* ------------ */

/* ========================= */
/* ========================= */

.AppBar1{
    background-color: #0a0080;
    border-radius: 9px 9px 9px 9px;
    padding: 0px 30px 0px 40px;
    margin: 2px;
}

/* =========================== */

.AppBar1{
    color: red;
}

/* =========================== */

/*out*/

.sinout {
/* bottom: 10px; */
    display: flex;
    /* position: fixed; */
    /* background: #e3004e; */
    background: #e3004ed4;
    color: #fff;
    /* border-radius: 3px 3px 12px 12px; */
    border-radius: 50px;
    margin-left: 5px;
    width: 239px;
}
