.fontss{
    font-family: system-ui;

}

.porderR{
    border-radius: 30px;
}

.steppers{
    border-radius: 10px 10px 0px 0px;
    background-color: blueviolet;
}

.botoninbotomm{
    max-width: none;
    padding: 0px 25px 0px 25px;
}